<template>
  <tablebox v-loading="loading"
    :element-loading-text="config.loading.text"
    :element-loading-spinner="config.loading.spinner"
    :element-loading-background="config.loading.background">
    <div class="dp-f bg-fff pt-20 pb-20 pr-20 pl-20">
      <tableLayout>
        <searchbox>
          <div class="input_box">
            <div class="input_title">用户名称</div>
            <el-input class="input" v-model="state.searchKey.username" placeholder="请输入用户名称" :maxlength="config.inputMaxlength" show-word-limit/>
          </div>
          <oabutton title="搜索" class="searcMR" @buttonclick="seach"></oabutton>
          <oabutton title="重置" CSStype=2 class="searcMR" @buttonclick="resetSeachData"></oabutton>
          <template v-slot:mr>
            <div class="dp-f">
              <oabutton title="新增" CSStype=2 class="searcMR" @buttonclick="unref(dialogRef).add()" v-permission="'system_user:add'" ></oabutton>
            </div>
          </template>
        </searchbox>
        <el-table :data="state.Notice" class="w100 mb-20"  :header-cell-style="{background:'var(--active-alpha)',color:config.table.color,fontWeight:config.table.fontWeight,fontSize:config.table.fontSize}" >
          <el-table-column :align="'center'" prop="username" label="用户名" />
          <el-table-column :align="'center'" prop="chnname" label="中文名" />
          <el-table-column :align="'center'" prop="phone" label="手机号" />
          <el-table-column :align="'center'" prop="role" label="角色">
            <template #default="scope">
              <div class="t-oh">
                <el-tag class="mr-5 ml-5 mb-5 mt-5" v-for="(el,ind) in scope.row.roleList" :key="ind">{{el.roleName}}</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column :align="'center'" prop="deptName" label="部门" />
          <el-table-column :align="'center'" prop="post" label="岗位">
            <template #default="scope">
              <div class="t-oh">
                <el-tag class="mr-5 ml-5 mb-5 mt-5" v-for="(el,ind) in scope.row.postList" :key="ind">{{el.postName}}</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column :align="'center'" label="状态">
            <template #default="scope">
              {{getstatus(scope.row.status)}}
            </template>
          </el-table-column>
          <el-table-column :align="'center'" prop="sort" label="排序" />
          <el-table-column :align="'center'" prop="createDate" label="创建时间" />
          <el-table-column :align="'center'" label="操作">
            <template #default="scope">
              <div class="dp-fc">
                <div class="Editor_span searcMR" @click="editclick(scope.row)" v-permission="'system_user:edit'">修改</div>
                <div class="Delete_span" @click="deteleClick(scope.row)" v-permission="'system_user:del'">删除</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination class="dp-f jc-fe" @current-change="handleCurrentChange" background layout="prev, pager, next,slot" :current-page="currentPage" :total="totalPage">
          <span class="ml-10">共{{totalofpages}}页</span>
        </el-pagination>
      </tableLayout>
    </div>
    <!-- 用户修改编辑弹框 -->
    <userDialog ref="dialogRef" @Emit="getdatalb(currentPage,state.datasearchjson)"></userDialog>
  </tablebox>
</template>
<script setup>
  import userDialog from "./user/dialog"
  import { onMounted,reactive,ref,unref,nextTick } from 'vue'
  import {checkisnull} from "@/utils/server/check";
  import {objdata_ret,objdata_set} from "@/utils/server/format";
  import {handleCofirm,handleMessage} from "@/utils/server/confirm";
  import {httpToken} from "@/utils/request";
  import {getstatus} from "@/utils/server/getcode";
  import qs from "qs";
  const dialogRef= ref(null);//修改编辑弹框ref
  const loading = ref(false);//loading 显示
  const currentPage = ref(0);//当前页数
  const totalPage = ref(0);//总条数
  const totalofpages = ref(1);//总页数
  const state = reactive({
    Notice : [],//表格数据
    datasearchjson:[],//当前缓存构造器
    // selectdata_state:getXialaListdata2(['启用','禁用'],[0,1]),//状态下拉数据
    searchKey:{
      username:'',//用户名
    },//搜索框绑定值
  })
  // 编辑点击
  const editclick=(row)=>{
    row.role=row.roleList.map(item=>{
      return item.roleId
    })
    row.post=row.postList.map(item=>{
      return item.postId
    })
    console.log('row',row)
    unref(dialogRef).editclick(row)
  }
  let seach=(()=>{
    let searchjson = search_return();
    getdatalb(currentPage.value,searchjson)
  })
  // 重置查询数据
  let resetSeachData=(()=>{
    objdata_ret(state.searchKey)
  })
  // 删除点击
  let deteleClick=((row)=>{
    if(row.userId==='1'){
      handleMessage('无法删除预置账户')
      return
    }
    handleCofirm().then(()=>{
      httpToken({
        method: "post",
        url: '/admin/sys-user/del',
        data:qs.stringify({
          ids:row.userId
        })
      }).then(()=>{
        getdatalb(currentPage.value,state.datasearchjson)
      })
    }).catch(()=>{
      handleMessage('已取消删除','info')
    })
  })
  let search_return=(()=> {
    let jsons = [];
    if (!checkisnull(state.searchKey.username)) {
      jsons.push({ column: "username", type: "like", value: state.searchKey.username });
    }
    return jsons;
  })
  let handleCurrentChange=((val)=>{
    getdatalb(val,state.datasearchjson)
  })
   // 列表调取 页数 传入构造器
  let getdatalb=((val, searchjson)=> {
    loading.value=true
    let json = [
      { column: "sort", type: 'orderByAsc' }
    ];
    for (let i = 0; i < searchjson.length; i++) {
      json.push(searchjson[i]);
    }
    currentPage.value=val;
    state.datasearchjson=searchjson;
    httpToken({
      method: "post",
      url: '/admin/sys-user/list',
      data: qs.stringify({
        page: val,
        paramData:JSON.stringify(json)
      }),
    }).then((res) => {
      // 如果存在请求页码和返回数据时页面不同的情况 按返回页码重新请求
      if(val!==res.data.current){
        getdatalb(res.data.current,searchjson)
        return
      }
      totalPage.value = res.data.total;
      totalofpages.value = res.data.pages;
      state.Notice=res.data.records
      loading.value=false;
    }).catch(()=>{
      loading.value=false;
    })
  })
  onMounted(() => {
    getdatalb(1,[])
  })
</script>
<style lang="scss" scoped >
@import "@/styles/general/element.scss";  //element 样式重置
@import "@/styles/general/element/table.scss"; //element 列表样式重置
@import "@/styles/general/element/pagination1.scss"; //element 分页样式重置
</style>